<template>
  <v-card class="configs-card" flat>
    <SettingsBlock :team-id="team_id"></SettingsBlock>
  </v-card>
</template>

<script>
import SettingsBlock from "../../../AppDashboard/Settings/components/SettingsBlock.vue";
export default {
  components: { SettingsBlock },
  computed: {
    team_id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.configs-card {
  blockquote {
    border-left: 3px solid gray;
    padding: 10px;
  }
}
</style>
